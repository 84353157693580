import type {
  CostType,
  ToolType,
} from '~/components/form/repairshop/utils/formTypes';

const TaxDE = 19;
const TaxAT = 20;
const TaxNL = 21;

export const usePriceData = (languageCode = 'de') =>
  useState<CostType>('priceData', () => ({
    subtotal: 0,
    shipping: 0,
    total: 0,
    tax: getTaxByLanguageCode(languageCode),
  }));

export function setPriceData(tools: ToolType[], languageCode = 'de') {
  if (!tools?.length) {
    return;
  }

  const priceData = usePriceData(languageCode);
  priceData.value.subtotal = 0;
  priceData.value.shipping = 0;
  priceData.value.total = 0;

  tools.forEach((tool) => {
    if (!tool.device) {
      return;
    }

    priceData.value.subtotal += Number(tool.device.price);
    priceData.value.shipping = 10;
  });

  priceData.value.total = priceData.value.subtotal + priceData.value.shipping;
}

export function resetPriceData(languageCode = 'de'): void {
  const priceData = usePriceData(languageCode);
  priceData.value.subtotal = 0;
  priceData.value.shipping = 0;
  priceData.value.total = 0;
  priceData.value.tax = getTaxByLanguageCode(languageCode);
}

function getTaxByLanguageCode(languageCode: string): number {
  switch (languageCode) {
    case 'de':
      return TaxDE;
    case 'nl':
      return TaxNL;
    case 'at':
      return TaxAT;
    default:
      return TaxDE;
  }
}
