export function getIconByHandle(handle: string): string {
  if (handle.includes('turbine') && !handle.includes('laborturbine')) {
    return useStaticImage('repairshop/turbine.svg');
  } else if (handle.includes('pauschaleFuerPruefungKleinstreparatur')) {
    return useStaticImage('repairshop/turbine.svg');
  } else if (handle.includes('winkelstueckRot')) {
    return useStaticImage('repairshop/winkelstueck_rot.svg');
  } else if (handle.includes('winkelstueckGruenBlau')) {
    return useStaticImage('repairshop/winkelstueck_gruen_blau.svg');
  } else if (handle.includes('kopf')) {
    return useStaticImage('repairshop/kopf.svg');
  } else if (handle.includes('kupplung')) {
    return useStaticImage('repairshop/kupplung.svg');
  } else if (handle.includes('handstueck')) {
    return useStaticImage('repairshop/handstueck.svg');
  } else if (handle.includes('scaler')) {
    return useStaticImage('repairshop/scaler.svg');
  } else if (handle.includes('prophyflexRondoflex')) {
    return useStaticImage('repairshop/prophyflex_rondoflex.svg');
  } else if (handle.includes('laborturbine')) {
    return useStaticImage('repairshop/labor_turbine.svg');
  } else if (handle.includes('winkelstueckOhneKopf')) {
    return useStaticImage('repairshop/winkelstueck_ohne_kopf.svg');
  }

  // Set turbine as default
  return useStaticImage('repairshop/turbine.svg');
}
